<template>
    <!-- <div class="text-body-1 my-2">
        <slot v-bind:text="text">{{ text }}</slot>
    </div> -->
    <v-alert border="left" colored-border color="primary">
        <div class="text-body-1">
            <slot v-bind:text="text">{{ text }}</slot>
        </div>
    </v-alert>
</template>

<script>
export default {
    name: 'Setup-Info',
    props: {
        text: {
            type: String,
            default: null
        }
    }
}
</script>